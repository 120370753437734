.custom-table-wrapper {
  background-color: #f8f8f8;
}

.custom-table {
  // margin: 15px 0;
}

.custom-table-head {
  border-top: 3px solid black;
  border-bottom: 2px solid black;

  tr {
    background-color: white;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #a0a0a0;
  border-radius: 10px;
}
.height_100 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 5%;
}
