@import "./styles/theme.scss";
// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";
// @import "../node_modules/bootstrap/scss/maps";
// @import "../node_modules/bootstrap/scss/utilities";
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/nav";
// @import "../node_modules/bootstrap/scss/helpers";
// @import "../node_modules/bootstrap/scss/utilities/api";
// @import "../node_modules/bootstrap/scss/badge";
// @import "../node_modules/bootstrap/scss/nav";

legend {
  float: none;
}

body {
  background-color: #f7f7f7;
  font-family: "Fira Sans", sans-serif;
  background-color: white;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #f7b742 !important;
  border-color: #f7b742 !important;
  color: #040405;
  padding: 10px 10px;
}

a,
a:hover,
a:active,
a:visited {
  color: #040405;
  text-decoration: none;
}

.form-control {
  padding: 25px 15px;

  &:focus {
    border-color: #f7b742;
    outline: none;
    box-shadow: none;
  }
}

.form-label {
  color: #262630;
}

.content-wrapper {
  position: relative;
  // padding: 128px 15px 15px 333px;
  padding: 6% 15px 15px 20%;
  width: 90%;
  min-height: 90vh;
  height: auto;
  margin: 0 auto;
}

.card-block {
  min-height: 100px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.form-group > .input-feedback {
  color: #fb7078;
  font-size: 13px;
  margin-top: 10px;
  padding-left: 10px;
  font-weight: 700;
}

button {
  color: #f7b742;

  &.link {
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: underline;
    }
  }
}

.search-box {
  max-width: 300px;
}

.rdt_TableHeader {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.preview-img {
  height: 50px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: #fafafa;
}

.text-right {
  text-align: right;
}

.material-icons {
  font-size: inherit;
  display: inline-flex;
  vertical-align: middle;
}

// @for $i from 10 through 30 {
//   .font-#{$i} {
//     font-size: #{$i}px;
//   }
// }

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
